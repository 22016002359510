<template>
  <div>
    <p>
      For each of the following sets of elements, <b>choose the two</b> that would be most likely to
      exhibit similar chemical properties.
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-row justify="left" class="pl-8">
        <v-col xl="3" lg="3" md="3" sm="12" xs="12">
          <p>
            <stemble-latex content="$\textbf{Set I}$" />
          </p>
          <div v-for="option in options1" :key="option.value">
            <v-checkbox
              v-model="inputs.input1"
              :value="option.value"
              :dense="true"
              hide-details="true"
              :ripple="false"
              :label="option.text"
            >
              <template #label>
                <stemble-latex :content="`$\\ce{${option.text}}$`" />
              </template>
            </v-checkbox>
          </div>
        </v-col>
        <v-col xl="3" lg="3" md="3" sm="12" xs="12">
          <p>
            <stemble-latex content="$\textbf{Set II}$" />
          </p>
          <div v-for="option in options2" :key="option.value">
            <v-checkbox
              v-model="inputs.input2"
              :value="option.value"
              :dense="true"
              hide-details="true"
              :ripple="false"
              :label="option.text"
            >
              <template #label>
                <stemble-latex :content="`$\\ce{${option.text}}$`" />
              </template>
            </v-checkbox>
          </div>
        </v-col>
        <v-col xl="3" lg="3" md="3" sm="12" xs="12">
          <p>
            <stemble-latex content="$\textbf{Set III}$" />
          </p>
          <div v-for="option in options3" :key="option.value">
            <v-checkbox
              v-model="inputs.input3"
              :value="option.value"
              :dense="true"
              hide-details="true"
              :ripple="false"
              :label="option.text"
            >
              <template #label>
                <stemble-latex :content="`$\\ce{${option.text}}$`" />
              </template>
            </v-checkbox>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question9',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
        input2: [],
        input3: [],
      },
      options1: [
        {text: 'He', value: 'helium'},
        {text: 'N', value: 'nitrogen'},
        {text: 'Ar', value: 'argon'},
        {text: 'H', value: 'hydrogen'},
        {text: 'Pd', value: 'palladium'},
      ],
      options2: [
        {text: 'Na', value: 'sodium'},
        {text: 'As', value: 'arsenic'},
        {text: 'Au', value: 'gold'},
        {text: 'P', value: 'phosphorus'},
        {text: 'He', value: 'helium'},
      ],
      options3: [
        {text: 'Kr', value: 'krypton'},
        {text: 'Na', value: 'sodium'},
        {text: 'Si', value: 'silicon'},
        {text: 'Rb', value: 'rubidium'},
        {text: 'Pd', value: 'palladium'},
      ],
    };
  },
};
</script>
